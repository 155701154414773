import React from 'react';

import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { BaseEmpty } from '../components/common';

export default () => {
  const { search } = useLocation();

  React.useEffect(() => {
    const params = queryString.parse(search);

    if (search && params.secret) {
      window.location = `https://account.boksnok.se${search}`;
    } else {
      window.location = `https://hej.boksnok.se${search || ''}`;
    }
  }, []);

  return <BaseEmpty />;
};
